//TODO: перенести на медиа запросы
.subscribe {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 21px;
  padding-bottom: 55px;

  @include breakpoint(mmd) {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  @include breakpoint(sm) {
    padding-bottom: 0;
  }

  &--inner {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-size: cover;
    background: url(../img/subscribe_bg.png) no-repeat center center;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(16, 16, 22, .5);
    }

    img {
      position: absolute;
      top: -23px;
      right: calc(485px / 2);
      width: 586px;
      height: 496px;

      @include breakpoint(xm) {
        right: 100px;
      }

      @include breakpoint(xx) {
        right: -50px;
      }

      @include breakpoint(mmd) {
        right: -50px;
        width: 400px;
        height: 300px;
        top: 60px;
      }

      @include breakpoint(sm) {
        right: unset;
        top: 100px;
        width: 325px;
        height: 270px;
        left: 50%;
        transform: translateX(-50%);
      }

      @include breakpoint(xss) {
        top: 150px;
      }
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 411px;
    position: relative;
    z-index: 100;
    padding-left: calc(20px + 85px);

    @include breakpoint(lg) {
      padding-left: 40px;
    }

    @include breakpoint(sm) {
      padding: 0 20px;
    }

    @include breakpoint(ssm) {
      padding: 0 8px;
    }

    h2 {
      strong {
        font-weight: 900;
      }

      @include breakpoint(sm) {
        height: 55px;
      }

      @include breakpoint(xss) {
        height: 80px;
        width: 320px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    h2,
    p {
      max-width: 374px;
      margin-bottom: 30px;

      @include breakpoint(mmd) {
        max-width: 60%;
      }

      @include breakpoint(sm) {
        max-width: 100%;
      }
    }

    h2 + p {
      margin-bottom: 44px;

      @include breakpoint(sm) {
        margin-bottom: 230px;
        text-align: center;
        width: 100%;
      }
    }

    .btn {
      width: 308px;
      height: 70px;
    }
  }
}

.subscribe--grid {
  position: relative;
  margin: 5rem 0;
  background: url(../img/subscribe_bg.png) no-repeat 50%;
  background-size: cover
}

.subscribe--grid .wrapper {
  display: grid;
  gap: 2rem;
  grid-template-columns:1fr
}

.subscribe--grid .wrapper div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.subscribe--grid .wrapper div > * {
  max-width: 500px
}

.subscribe--grid .wrapper div > img {
  width: 100%;
  height: 100%
}

@media (min-width: 1024px) {
  .subscribe--grid .wrapper {
    grid-template-columns:1fr 1fr;
    padding-inline: 8rem
  }

  .subscribe--grid .wrapper div {
    text-align: left;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
  }

  .subscribe--grid .wrapper div:nth-child(2) {
    grid-row: span 2
  }
}
