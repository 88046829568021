.homepage {
  min-width: 0;
}

//TODO: перенести на медиа запросы
.regards {
  margin-bottom: 16px
}

.regards__grid {
  display: flex;
  justify-content: space-between;
  align-items: center
}

@media screen and (max-width: 992px) {
  .regards__grid {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: -16px 0
  }
}

@media screen and (max-width: 480px) {
  .regards__grid {
    padding: 0 32px
  }
}

.regards__grid__item {
  display: flex;
  align-items: center
}

@media screen and (max-width: 992px) {
  .regards__grid__item {
    margin: 16px 0
  }
}

@media screen and (max-width: 480px) {
  .regards__grid__item:nth-child(2) {
    margin-left: -8px
  }
}

@media screen and (max-width: 480px) {
  .regards__grid__item:nth-child(2) img {
    margin-right: 10px
  }
}

.regards__grid__item img {
  margin-right: 16px;
  object-fit: contain
}

@media screen and (max-width: 480px) {
  .regards__grid__item img {
    max-height: 47px
  }
}

@media screen and (max-width: 480px) {
  .regards__grid__item img:last-of-type {
    max-height: 50px
  }
}

.regards__grid__item p {
  font-size: 20.5px;
  font-weight: 600
}

@media screen and (max-width: 480px) {
  .regards__grid__item p {
    font-size: 18px
  }
}

.loadmore {
  max-width: 220px;
  margin: 40px auto
}

@media screen and (max-width: 600px) {
  .loadmore {
    margin: 80px auto 0;
    max-width: calc(100% - 40px) !important
  }
}

.video {
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center
}

@media screen and (max-width: 1100px) {
  .video {
    flex-direction: column-reverse;
    justify-content: flex-start
  }
}

@media screen and (max-width: 600px) {
  .video {
    margin-top: 40px
  }
}

.video__item:first-child {
  margin-right: 50px;
  width: 100%;
  max-width: 720px;
  flex-shrink: 0
}

@media screen and (max-width: 1100px) {
  .video__item:first-child {
    margin-right: 0;
    margin-top: 40px
  }
}

.video__item:first-child video {
  width: 100%;
  max-width: 100%
}

.video__item:last-child p {
  font-size: 30px;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 40px;
  opacity: 1
}

@media screen and (max-width: 1100px) {
  .video__item:last-child p {
    margin-bottom: 0;
    margin-right: 40px
  }
}

@media screen and (max-width: 600px) {
  .video__item:last-child p {
    font-size: 20px;
    margin-right: 0
  }
}

@media screen and (max-width: 1100px) {
  .video__item:last-child {
    width: 735px;
    max-width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    align-items: center
  }
}

@media screen and (max-width: 600px) {
  .video__item:last-child {
    flex-direction: column;
    justify-content: flex-start;
    text-align: center
  }
}

.video__item .btn {
  min-width: 290px;
  left: unset;
  transform: unset
}

@media screen and (max-width: 768px) {
  .video__item .btn {
    min-width: 220px
  }
}

@media screen and (max-width: 600px) {
  .video__item .btn {
    display: none;
    margin-top: 30px
  }
}

@media screen and (max-width: 380px) {
  .video__item .btn {
    max-width: calc(100% - 20px)
  }
}

.video__item .btn_mobile {
  display: none
}

@media screen and (max-width: 600px) {
  .video__item .btn_mobile {
    display: flex
  }
}
