.main_menu_toggle {
  width: 35px;
  height: 14px;
  background: url(../img/menu_toggle.png) no-repeat center center;
  background-size: contain;
  cursor: pointer;
  flex-shrink: 0;
}

.main_menu {
  min-width: 360px;
  height: auto;
  z-index: 500;
  position: fixed;
  top: 0;
  left: 0;
  transition: all .3s ease-in-out;
  box-shadow: 6.8px 7.3px 7.4px 1.6px rgba(175, 24, 116, 0.27);
  background-color: rgba(19, 1, 26, .94);
  padding: 120px 50px 90px 50px;
  overflow: auto;
  transform: translateX(-110%);
  will-change: transform;

  @include breakpoint(md) {
    width: 400px;
    height: 100vh;
    padding: 148px 42px 100px 70px;
  }

  @include breakpoint(ssm) {
    width: 100%;
    max-width: 100%;
    min-width: 100vw;
    padding: 150px 15px 120px 25px;
  }

  &.show {
    transform: translateX(0);
  }
}

.main_menu .header__logo {
  position: absolute;
  width: 122px;
  top: 45px;
  left: 50px;
  margin-left: 0;

  @include breakpoint(ssm) {
    left: 25px;
    transform: none;
    justify-content: flex-start;
  }
}

.main_menu--close {
  position: absolute;
  cursor: pointer;
  top: 50px;
  right: 50px;
  background: url(../img/cross.svg) no-repeat center center;
  width: 16px;
  height: 16px;
  transition: all .3s ease-in-out;

  &:hover {
    transform: rotate(-180deg);
  }

  @include breakpoint(ssm) {
    right: 25px;
  }
}

.main_menu__title {
  font-weight: 600;
  font-size: 18px;
  margin-top: 50px;
  margin-bottom: 13px;
}

.main_menu ul {
  li {
    padding-left: 50px;
    position: relative;
    margin-bottom: 16px;

    a {
      font-size: 16px;

      &:hover {
        text-decoration: underline;
      }
    }

    &:before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      background-size: contain;
    }

    &:nth-of-type(1):before {
      background: url(../img/house.svg) no-repeat center center;
      width: 24px;
      height: 24px;
    }

    &:nth-of-type(2):before {
      background: url(../img/clock.svg) no-repeat center center;
      width: 21px;
      height: 21px;
      left: 1px;
      top: 2px;
    }

    &:nth-of-type(3):before {
      background: url(../img/star-solid.svg) no-repeat center center;
      width: 28px;
      height: 28px;
      left: -2px;
      top: -3px;
    }

    &:nth-of-type(4):before {
      background: url(../img/settings.svg) no-repeat center center;
      width: 22px;
      height: 22px;
    }
  }

  &:last-of-type {
    li {
      padding-left: 25px;

      &:before {
        top: 8px;
        left: 0;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.9);
      }
    }
  }
}
