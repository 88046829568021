.page__courses {
  h1 {
    @include h2;
    margin-bottom: 38px;
  }

  section:first-of-type h2:first-of-type {
    margin-top: 0;
  }

  .slider_category {
    font-size: 25px;
    font-weight: 400;
    margin-top: 40px;
    margin-bottom: 40px;

    @include breakpoint(ssm) {
      font-size: 22px;
    }
  }
}
