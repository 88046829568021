* {
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  box-sizing: border-box;
}

body {
  @include text;
  background-color: $main-bg;

  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1 1 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.wrapper {
  @include main_container;
}

section {
  max-width: 100%;
  overflow: hidden;
}

ul {
  list-style-type: none;
}

h1 {
  @include h1;
}

h2 {
  @include h2;
}

h3 {
  @include h3;
}

p {
  @include text;
}

img {
  max-width: 100%;
  object-fit: contain;
  object-position: center center;
}

header {
  input[type='text'],
  input[type='email'] {
    @include input;
  }
}

a {
  @include link;
}

strong {
  font-weight: 600;
  font-size: inherit;
}

.btn {
  @include btn;

  &--secondary {
    @include btn;
    @include btn_secondary;
  }
}

.category {
  @include blue_sticker;
}

.accordeon {
  @include accordeon;
}

section h2 {
  font-size: 30px;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 40px;

  @include breakpoint(ssm) {
    font-size: 22px;
  }
}

.icons {
  display: flex;
  align-items: center;

  > a {
    background-color: $blue;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      object-fit: contain;
    }
  }
}

.salary {
  padding-bottom: 7px;
  position: relative;
  font-weight: 600;

  &:after {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: $blue;
  }
}

.authors {
  p {
    font-size: 16px;
  }
}

.page_top {
  display: flex;
  justify-content: space-between;

  @include breakpoint(sm) {
    flex-direction: column;
  }
}

.filters {
  display: flex;
  align-items: center;
  @include breakpoint(sm) {
    flex-wrap: wrap;
    margin-bottom: 35PX;
  }

  span {
    font-size: 14px;
    line-height: 120%;
    font-weight: 600;
    display: inline-block;
    margin-right: 10px;
  }

  a {
    font-size: 14px;
    line-height: 120%;
    display: inline-block;
    margin-right: 3px;

    &:hover {
      &:after {
        color: #FFF;
      }
    }
  }

  & a:not(:last-child) {
    &:after {
      content: '/';
      display: inline-block;
      margin: 0 3px;

      &:hover {
        color: #FFF;
      }
    }
  }

  a.active {
    font-weight: 600;
  }
}

.courses__list {
  h3 {
    margin: 10px 0;
    text-transform: uppercase;
  }

  p a {
    font-size: 16px;
    line-height: 120%;
  }
}

.courses__list + .courses__list {
  margin-top: 42px;
}
