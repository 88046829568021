.page__course_detail {
  .editors_choice {
    margin-bottom: 49px;

    > div {
      align-items: flex-start;
      @include breakpoint(xx) {
        flex-direction: column;
      }
    }

    h1 {
      @include h2;

      @include breakpoint(sm) {
        font-size: 18px;
        padding-bottom: 8px;
        margin-bottom: 22px;
      }

      & + p, & + p + p {
        @include breakpoint(sm) {
          font-size: 16px;
        }
      }
    }

    .breadcrumbs {
      & + p {
        margin-bottom: 20px;
        font-size: 24px;

        @include breakpoint(sm) {
          font-size: 18px;
          text-align: center;
        }
      }

      @include breakpoint(sm) {
        display: none;
      }

      &--mobile {
        display: none;
        font-size: 10px;
        margin-bottom: 14px;
        @include breakpoint(sm) {
          display: block;
        }
      }
    }

    ul.details {
      margin-bottom: 20px;

      @include breakpoint(sm) {
        padding: 0 14px 0 30px;
      }
    }

    .authors {
      margin-top: 40px;
      margin-bottom: 20px;

      @include breakpoint(sm) {
        margin-top: 30px;
        margin-bottom: 0;
      }

      p:first-of-type {
        font-size: 16px;
        margin-bottom: 13px;
      }
    }

    ul.details + p, .authors p {
      font-size: 12px;
    }

    .icons {
      @include breakpoint(sm) {
        left: 10px;
        top: 30px;
      }
    }

    .category {
      @include breakpoint(sm) {
        right: 10px;
        top: 30px;
        padding: 6px 8px 5px;
        max-width: 147px;
      }
    }

    &__hero {
      width: 65%;
      max-width: unset;
      @include breakpoint(xx) {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 31px;
      }

      &--inner {
        @include breakpoint(sm) {
          padding: 30px 10px 33px;
        }
      }
    }

    &__info {
      width: 35%;
      @include breakpoint(xx) {
        width: 70%;
      }

      @include breakpoint(md) {
        width: 100%;
        max-width: 100%;
      }

      @include breakpoint(sm) {
        display: flex;
        flex-direction: column;
      }

      .salary {
        @include breakpoint(sm) {
          order: -1;
          padding: 0 14px 10px;
          text-align: center;
          display: flex;
          justify-content: center;
          margin-bottom: 38px;
        }

        @include breakpoint(ssm) {
          justify-content: flex-start;
          text-align: left;
        }
      }

      .breadcrumbs + p {
        @include breakpoint(sm) {
          order: -1;
          padding: 0 14px;
        }
      }
    }
  }
}

.mini_carousel {
  margin-bottom: 31px;

  h2 {
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  .slick-slide {
    @include course_mini_card;
  }

  .slick-list {
    margin: 0 18px;
  }

  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;
  }
}
