.course_card {
  @include course_card;

  &--small {
    h3 {
      display: inline;
      font-size: 10px;
      line-height: 120%;
      word-break: break-word;
      white-space: break-spaces;
    }

    .category {
      font-size: 10px;
      line-height: 120%;
      padding: 5px;
    }
  }
}

.course_card-preview {
  height: 222px;
  position: relative;
  background-color: rgba(23, 23, 28, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 50px;
  outline: 2px solid transparent;
  outline-offset: -2px;
  transition: all 0.2s ease-in-out;

  &:hover {
    outline-color: rgba(255, 255, 255, 0.5);
  }
}

.course_card-preview img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.course_card-preview p {
  font-size: 26px;
  font-weight: 600;

  &:first-of-type {
  }

  &:last-of-type {
  }
}
