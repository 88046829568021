.slider {
  margin: 40px 0;
  width: 100%;

  @include breakpoint(sm) {
    margin: 60px 0;
  }

  .slick-list {
    margin: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
}

.slider__item {
  margin: 0 !important;
  position: relative;

  > img {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.slider__item--inner {
  position: relative;
  background-color: rgba(23, 23, 28, .7);
  width: 100%;
  height: 100%;
  z-index: 100;
}

.slider__item .wrapper {
  display: flex;
  width: 100%;
  height: 100%;

  @include breakpoint(md) {
    flex-wrap: wrap-reverse;
  }
}

.slider__item .authors {
  padding: 35px 15px;
  max-width: 30%;
  min-width: 30%;
  margin-right: 45px;

  @include breakpoint(md) {
    max-width: 100%;
    width: 100%;
    margin-right: 0;
  }

  p {
    &:first-of-type {
      margin-bottom: 30px;
    }
  }
}

.slider__item .icons {
  margin-bottom: 62px;

  > a {
    width: 67px;
    height: 67px;

    img {
      width: 80%;
      height: 80%;
      object-fit: contain;
    }

    &:first-of-type {
      margin-right: 29px;
    }
  }

  @include breakpoint(sm) {
    display: none;
  }

  &_mobile {
    display: none;

    @include breakpoint(sm) {
      display: flex;
      margin-bottom: 0;

      > a {
        width: 33px;
        height: 33px;
      }
    }
  }
}

.slider__item .info {
  padding: 35px 15px;

  .salary {
    margin-left: 66px;
    font-size: 16px;

    @include breakpoint(sm) {
      display: none;
      margin-left: 0;
      margin-top: 30px;
    }

    &_mobile {
      display: none;
      @include breakpoint(sm) {
        display: block;
      }
    }
  }

  h2 {
    @include h2;
    margin-bottom: 16px;
  }

  .subs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    width: 100%;
    max-width: 413px;
    margin-bottom: 52px;

    @include breakpoint(sm) {
      max-width: 100%;
      font-size: 18px;
    }
  }

  .btn {
    margin-top: 30px;
    width: 315px;
    height: 71px;
    margin-left: 48px;
    padding: 0;

    @include breakpoint(sm) {
      display: none;
      margin-left: auto;
      margin-right: auto;
      width: 100% !important;
    }

    &_mobile {
      display: none;

      @include breakpoint(sm) {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100% !important;
      }
    }
  }
}

.slider__item .info__head {
  display: flex;
  align-items: center;
  margin-bottom: 32px;

  @include breakpoint(sm) {
    justify-content: space-between;
  }
}

.slider .btn_mobile {
  display: none;

  @include breakpoint(sm) {
    display: flex;
    margin-bottom: 50px;
    order: -1;
  }
}
