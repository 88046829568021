.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  margin-top: 38px;
  margin-bottom: 59px;

  @include breakpoint(md) {
    flex-direction: column;
    padding: 0 20px;
  }

  @include breakpoint(sm) {
    padding-bottom: 45px;
    margin-bottom: 0;
  }

  &-personal {
    & .header__personal__user {
      background-color: #feb675;
      border: 2px solid #e342fa;
    }

    & .header__personal__notify {
      display: none;
    }

    & .btn__pay {
      display: none;
    }

    & .header__personal__contact,
    & .header__personal__logout {
      display: block;
    }

    & .header__personal__contact {
      @include breakpoint(sm) {
        display: none;
      }
    }
  }
}

.header__logo {
  display: block;
  min-width: 122px;
  height: 23px;
  flex-shrink: 0;
}

@media screen and (max-width: 768px) {
  .header__logo {
    display: none
  }
}

.header__logo_mobile {
  display: none
}

@media screen and (max-width: 768px) {
  .header__logo_mobile {
    display: block;
    margin: 0 auto 24px
  }
}

.header__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.header__links {
  display: flex;
  align-items: center;

  a {
    font-size: 14px;
    @include link;
  }

  & li:first-of-type {
    margin-right: vw(39, 1920);
  }

  @include breakpoint(xx) {
    display: none;
  }
}

.header__search {
  width: 100%;
  max-width: 250px;
  min-height: 20px;
  position: relative;
  flex-shrink: 0;

  input {
    @include breakpoint(mmd) {
      display: none !important;
    }
  }
}

.header__search__btn {
  position: absolute;
  width: 18px;
  height: 18px;
  background: url(../img/search.png) no-repeat center center;
  left: 23px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 0;

  &:after {
    display: none;
  }
}

.header .btn {
  font-size: 14px;
  font-weight: 500;

  strong {
    font-size: 16px;
  }

  @include breakpoint(md) {
    order: 7;
  }
}

.header .btn__pay {
  @include breakpoint(sm) {
    display: none;
  }
}

.header .btn__pay.btn_mobile {
  display: none;
  @include breakpoint(sm) {
    display: none;
  }
}

.header__personal {
  display: flex;
  align-items: center;;
  justify-content: flex-end;
  gap: 24px;
  width: 100%
}

.header__personal__notify {
  width: 21px;
  height: 23px;
  background: url(../img/bell.png) no-repeat center center;
  background-size: contain;
  flex-shrink: 0
}

.header__personal__contact {
  display: none;
  font-size: 16px;
  line-height: 120%;
  font-weight: 600;
  flex-shrink: 0;
}

.header__personal__logout {
  display: none;
  font-size: 14px;
  line-height: 120%;
  font-weight: 400;
}

.header__personal__user {
  width: 45px;
  height: 45px;
  background: url(../img/user.png) no-repeat center center;
  background-size: 18px 22px;
  position: relative;
  border: 2px solid #bbbbbc;
  border-radius: 50%;
  padding: 10px;
  opacity: .85;
  flex-shrink: 0;
}

.header.courses_category {
  .btn_mobile {
    @include breakpoint(sm) {
      display: flex;
      margin: 25px auto;
      order: 1;
    }
  }
}

.header-personal .header__personal__user {
  background-color: #feb675;
  border: 2px solid #e342fa
}

@media screen and (max-width: 600px) {
  .header-personal .header__personal__contact {
    display: none
  }
}

.header__info {
  justify-content: space-between
}

.header__controls, .header__info {
  display: flex;
  align-items: center;
  width: 100%
}

.header__controls {
  justify-content: flex-start;
  gap: 24px
}

.header__link {
  display: inline-block;
  flex-shrink: 0;
  font-size: 14px;
  text-decoration: none;
  color: #fff;
  -webkit-transition: .2s ease-in-out;
  -o-transition: .2s ease-in-out;
  transition: .2s ease-in-out
}

.header__link:hover {
  color: #c544f5
}

@media screen and (max-width: 1100px) {
  .header__link {
    display: none
  }
}

.header__search-mobile {
  display: none;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  padding: 200px 20px 20px;
  background: rgba(16, 16, 22, .95);
  z-index: 1000
}

.header__search-mobile-close {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 24px;
  height: 24px;
  padding: 0;
  border: none;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z' fill='%23fff'/%3E%3C/svg%3E")
}

.header__search__btn-toggle {
  display: none;
  width: 18px;
  height: 18px;
  background: url(../img/search.png) no-repeat 50%;
  padding: 0
}

.header__search__btn-toggle:after {
  display: none
}

@media screen and (max-width:900px) {
  .header__search__btn-toggle {
    display: block
  }
}

@media screen and (max-width:900px) {
  .header__search {
    display: none;
    width: 20px
  }
}

.header__search--mobile {
  display: block;
  width: 100%;
  max-width: 100%
}

.header__search--mobile .header__search__btn {
  left: 20px
}

.header__search--mobile input {
  width: 100%;
  max-width: 100%
}

@media screen and (max-width:900px) {
  .header__search--mobile.active input {
    display: block !important
  }
}

@media screen and (max-width:900px) {
  .header__search__btn {
    left: 0;
    height: 18px !important;
    width: 18px !important;
    max-width: 18px !important
  }
}

@media screen and (max-width: 900px) {
  .header .btn__pay {
    order: -1
  }
}

@media screen and (max-width: 600px) {
  .header .btn__pay {
    display: none
  }
}
