.profile {

}

.profile__main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(5, 1fr);
  gap: 24px;
  padding: 0 35px 35px;

  @include breakpoint(mmd) {
    display: flex;
    flex-direction: column;
    padding: 0 0 35px;
    gap: 16px;
  }
}

.profile__block {
  padding: 20px 35px;
  border-radius: 12px;
  background-color: #1f1e25;

  &:first-child {
    grid-row: 1/5;
  }

  &:last-child {
    grid-column: 1/3;
  }

  @include breakpoint(mmd) {
    padding: 18px 24px;
  }
}

.profile__block-inner {
  display: flex;
  align-items: center;
  height: 100%;

  &--center {
    justify-content: center;

    @include breakpoint(mmd) {
      justify-content: flex-start;
    }
  }

  &:hover {
    & .profile__block-link--icon {
      transform: translateX(5px);
    }
  }
}

.profile__block-icon {
  width: 30px;
  height: 30px;
  margin-right: 18px;
  flex-shrink: 0;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.profile__block-input {
  border-radius: 12px;
  border: 1px solid #515056;
  padding: 10px;
  background-color: transparent;
  font-size: 14px;
  line-height: 120%;
  max-width: 150px;
  color: #FFF;

  @include breakpoint(mmd) {
    margin: 0;
  }
}

.profile__block-link {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
  color: #d4d4d5;

  &--text {
    font-size: 14px;
    line-height: 120%;
    font-weight: 400;

    @include breakpoint(mmd) {
      display: none;
    }
  }

  &--icon {
    width: 18px;
    height: 24px;
    flex-shrink: 0;
    margin-left: 8px;
    color: #d4d4d5;
    transition: all .3s ease-in-out;

    & svg {
      width: 100%;
      height: 100%;
    }
  }
}

.profile__block-title {
  display: inline-block;
  font-size: 14px;
  line-height: 120%;
  font-weight: 600;
  margin-right: 18px;
  max-width: 290px;

  &--input {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    max-width: unset;
    flex: 1 1 100%;

    @include breakpoint(mmd) {
      flex: initial;

      .profile__block-title--text {
        margin: 0;
      }
    }
  }

  &--text {
    display: inline-block;
    font-size: 14px;
    line-height: 120%;
    font-weight: 600;
    margin-right: 18px;

  }

  &--uppercase {
    font-size: 21px;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 35px;
  }

  &--normal {
    font-weight: 400;
  }

  & a {
    font-size: 14px;
    line-height: 120%;
    font-weight: 600;
  }
}

.profile__person,
.profile__person-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 1 100%;
}

.profile__person-row {
  display: flex;
  align-items: flex-end;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  &:first-child .profile__person-input {
    max-width: 300px;
    margin-right: 0;
  }

  @include breakpoint(mmd) {
    align-items: flex-start;
    flex-direction: column;

    &:first-child .profile__person-input {
      max-width: 100%;
    }
  }
}

.profile__person-title {
  font-size: 14px;
  line-height: 120%;
  font-weight: 600;
  flex-shrink: 0;
}

.profile__person-input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #515056;
  text-align: center;
  padding: 5px 10px;
  margin-left: auto;
  margin-right: calc((300px - 200px)/2);
  width: 100%;
  max-width: 200px;
  font-size: 14px;
  line-height: 120%;
  color: #FFF;

  @include breakpoint(mmd) {
    max-width: 100%;
    margin-top: 14px;
  }
}

.profile__person .btn {
  margin: 20px auto 0;
  font-size: 16px;
  line-height: 120%;
  border-width: 1px;
  border-radius: 10px;

  @include breakpoint(mmd) {
    margin-top: 20px;
  }
}
