$main-bg: #101016;
$secondary-bg: #a2a2a4;
$main-text-color: #fff;
$secondary-text-color: #a2a2a4;
$text-grey: #737375;

$button-bg-color: linear-gradient(30deg, #e342fa 0%, #ff0145 100%);
// linear-gradient(30deg, #e342fa 0%, #e342fa 35%, #ff0145 65%, #ff0145 100%);
// $button-bg-reverse-color: linear-gradient(30deg, #ff0145 0%, #ff0145 35%, #e342fa 65%, #e342fa 100%);
// $button-bg-reverse-color: linear-gradient(30deg, #c544f5 0%, #c544f5 35%, #c544f5 65%, #c544f5 100%);

$accent-color: #00bccb;
$blue: #04bbc9;

$hover_link_color: #c544f5;

// $section-mb         : 160px;
// $section-mb-tablet  : 130px;
// $section-mb-mobile  : 100px;

$container: '1100px';
