//TODO: перенести на медиа запросы
.footer {
  padding-top: 67px;
  padding-bottom: 82px;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto
}

.footer:before {
  position: absolute;
  content: "";
  width: calc(100% - 120px);
  height: 1px;
  top: 0;
  left: 60px;
  background-color: hsla(0, 0%, 100%, .6)
}

@media screen and (max-width: 1100px) {
  .footer:before {
    width: 100%;
    left: 0
  }
}

.footer a {
  display: block
}

.footer a, .footer li {
  line-height: 180%
}

.footer .btn {
  font-weight: 400;
  margin-bottom: 25px
}

@media screen and (max-width: 600px) {
  .footer .btn {
    margin: 0 0 24px;
    width: auto !important;
    height: auto !important
  }
}

@media screen and (max-width: 600px) {
  .footer {
    padding-top: 35px;
    padding-bottom: 35px
  }
}

.footer .wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap
}

@media screen and (max-width: 1100px) {
  .footer .wrapper {
    flex-wrap: wrap
  }
}

@media screen and (max-width: 600px) {
  .footer .wrapper {
    justify-content: unset
  }
}

.footer--col {
  width: auto;
  min-width: 200px;
  max-width: 300px
}

@media screen and (max-width: 1100px) {
  .footer--col {
    max-width: 30%;
    width: 30%
  }
}

@media screen and (max-width: 900px) {
  .footer--col {
    width: 50%;
    max-width: 50%
  }
}

@media screen and (max-width: 600px) {
  .footer--col {
    width: 320px;
    max-width: 100%
  }
}

@media screen and (max-width: 380px) {
  .footer--col {
    width: 300px
  }
}

@media screen and (max-width: 900px) {
  .footer--col:nth-child(3) {
    margin-top: 35px
  }
}

@media screen and (max-width: 600px) {
  .footer--col:nth-child(3) {
    margin-top: 24px
  }
}

.footer--col:last-child * {
  font-size: 14px;
  line-height: 2.5
}

.footer--col:last-child p {
  margin: 3px 0
}

@media screen and (max-width: 1100px) {
  .footer--col:last-child {
    column-count: 3;
    width: 100%;
    max-width: 100%;
    margin-top: 40px
  }
}

@media screen and (max-width: 900px) {
  .footer--col:last-child {
    column-count: 1;
    width: 50%;
    max-width: 50%
  }
}

@media screen and (max-width: 600px) {
  .footer--col:last-child {
    width: 320px;
    max-width: 100%;
    margin-top: 24px
  }
}

.footer__logo {
  width: 234px;
  height: 45px;
  margin-bottom: 74px
}

@media screen and (max-width: 600px) {
  .footer__logo {
    width: 100%;
    max-width: 122px;
    height: 23px;
    margin: 0 auto
  }
}

.footer__title {
  font-size: 22px;
  font-weight: 600
}

@media screen and (max-width: 600px) {
  .footer__title {
    margin-top: 24px;
    font-size: 16px
  }
}

.footer__quick-links {
  margin-top: 44px
}

.footer__quick-links li:before {
  display: none
}

.footer__quick-links li a {
  font-size: 16px;
  font-weight: 600
}

@media screen and (max-width: 600px) {
  .footer__quick-links {
    margin-top: 12px
  }

  .footer__quick-links li a {
    font-size: 14px
  }
}

.footer__topics {
  margin-top: 33px
}

.footer__topics li {
  padding-left: 25px;
  position: relative
}

@media screen and (max-width: 600px) {
  .footer__topics li {
    padding-left: 12px
  }
}

.footer__topics li:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: hsla(0, 0%, 100%, .9)
}

@media screen and (max-width: 600px) {
  .footer__topics li:before {
    width: 4px;
    height: 4px
  }
}

.footer__topics li a {
  font-size: 18px
}

@media screen and (max-width: 600px) {
  .footer__topics li a {
    font-size: 14px
  }
}

@media screen and (max-width: 600px) {
  .footer__topics {
    margin-top: 12px
  }
}

.footer__blog-link, .footer__contact-us-link {
  position: relative;
  padding-left: 58px;
  font-weight: 600;
  line-height: 1 !important
}

.footer__blog-link:before, .footer__contact-us-link:before {
  position: absolute;
  content: "";
  left: 0;
  top: 40%;
  transform: translateY(-50%)
}

@media screen and (max-width: 600px) {
  .footer__blog-link, .footer__contact-us-link {
    padding-left: 35px
  }
}

.footer__blog-link {
  font-size: 25px;
  margin-bottom: 39px
}

.footer__blog-link span {
  color: #35b34a
}

.footer__blog-link:before {
  width: 36px;
  height: 36px;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  background-image: url(../img/footer/human.png)
}

@media screen and (max-width: 600px) {
  .footer__blog-link {
    font-size: 16px;
    margin-bottom: 24px
  }

  .footer__blog-link:before {
    width: 24px;
    height: 24px
  }
}

.footer__contact-us-link {
  font-size: 22px;
  margin-bottom: 31px
}

.footer__contact-us-link:before {
  width: 42px;
  height: 33px;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  background-image: url(../img/footer/chat.png)
}

@media screen and (max-width: 600px) {
  .footer__contact-us-link {
    margin-bottom: 24px;
    font-size: 16px
  }

  .footer__contact-us-link:before {
    width: 24px;
    height: 24px
  }
}

.footer__adv {
  display: flex;
  margin: 0 -28px
}

.footer__adv > div {
  max-width: 130px;
  margin: 0 28px
}

.footer__adv > div p {
  font-size: 14px;
  margin-bottom: 10px
}

.footer__adv > div:last-of-type img:first-of-type {
  display: block;
  margin-bottom: 7px
}

.footer__adv > div img {
  margin-right: 18px
}
