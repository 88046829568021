.hero {
  margin: 0 auto 49px;
  position: relative;

  .slick-slide {
    max-width: 1210px;
    width: 100%;
    height: 624px;
    position: relative;
    margin: 0 18px;

    @include breakpoint(xm) {
      max-width: 1004px;
      height: calc(400px + 130px);
    }

    @include breakpoint(md) {
      max-width: 708px;
      height: calc(320px + 130px);
    }

    @include breakpoint(ssm) {
      max-width: 100%;
      height: calc(178px + 130px);
    }

    .hero__img {
      position: relative;
      height: 100%;

      @include breakpoint(xm) {
        height: calc(100% - 130px);
      }

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        max-width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        z-index: -1;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(23, 23, 28, 0.5);
      }
    }

    .hero--inner {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      > * {
        opacity: 0;
        visibility: hidden;
        transition: all .5s ease-in-out;
      }

      @include breakpoint(xm) {
        padding: 35px 40px 160px;
      }

      .hero__title {
        @include text;
        font-size: 80px;
        font-weight: 700;
        line-height: 1;
        width: 563px;
        max-width: 100%;
        margin: 0 0 60px;
        text-transform: none;
        text-align: left;

        @include breakpoint(xm) {
          font-size: 60px;
        }

        @include breakpoint(md) {
          font-size: 46px;
          margin: 0 0 20px;
        }

        @include breakpoint(ssm) {
          font-size: 24px;
          max-width: 265px;
          line-height: 1.15;
        }

        & + p {
          @include text;
          margin-bottom: 70px;
          font-size: 30px;
          font-weight: 500;

          @include breakpoint(xm) {
            margin-bottom: 0;
          }

          @include breakpoint(md) {
            font-size: 24px;
          }

          @include breakpoint(ssm) {
            font-size: 20px;
          }
        }
      }

      .btn {
        width: 315px;
        height: 70px;

        @include breakpoint(xm) {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }

        @include breakpoint(sm) {
          width: 283px !important;
          height: 70px !important;
        }
      }
    }
  }

  .slick-list {
    margin: 0 -18px;
  }

  .slick-prev, .slick-next {
    width: 27px;
    height: 46px;
    z-index: 10;

    @include breakpoint(xm) {
      top: unset;
      transform: none;
      bottom: 85px;
      width: 17px;
      height: 28px;
    }

    @include breakpoint(sm) {
      width: 11px;
      height: 20px;
      bottom: 94px;
    }
  }

  .slick-prev {
    left: calc((100vw - 1210px) / 2 - 80px);
  }

  .slick-next {
    right: calc((100vw - 1210px) / 2 - 80px);
  }

  .slick-current {
    > .hero--inner > * {
      opacity: 1;
      visibility: visible;
    }
  }

  .slick-dots {
    bottom: 90px;
    width: fit-content;
    left: 50%;
    transform: translateX(-50%);

    @include breakpoint(sm) {
      bottom: 95px;
    }

    li {
      width: 10px;
      height: 10px;
      background-color: #a7a7a7;
      margin: 0 4px;
      border-radius: 50%;

      &.slick-active {
        background: linear-gradient(to top, #c544f5, #e90345);;
      }

      button {
        &:before {
          content: '';
        }
      }
    }
  }
}
