.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, .7);
}

.modal[data-step="2"] {
  .modal__header:after {
    width: calc(((100% / 3) * 2) + 70px);

    @include breakpoint(sm) {
      width: calc(((100% / 3) * 2) + 40px);
    }
  }
}

.modal[data-step="3"] {
  .modal__header:after {
    width: calc(100% + 70px);

    @include breakpoint(sm) {
      width: calc(100% + 40px);
    }
  }
}

.modal__wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 580px;
  padding: 35px;
  background-color: $main-bg;

  @include breakpoint(sm) {
    padding: 20px;
  }
}

.modal__header {
  position: relative;
  display: grid;
  grid-template-columns: 122px 1fr 122px;
  align-content: center;
  gap: 14px;
  padding-bottom: 24px;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: -35px;
    width: calc(100% + 70px);
    height: 2px;
    background-color: #312b45;

    @include breakpoint(sm) {
      width: calc(100% + 40px);
    }
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -35px;
    width: calc(100% / 3);
    height: 2px;
    background-color: #d01361;

    @include breakpoint(sm) {
      width: calc(100% / 3);
    }
  }

  @include breakpoint(sm) {
    padding-bottom: 16px;
    grid-template-columns: 50px 1fr 18px;
  }
}

.modal__title {
  font-weight: 600;
  text-align: center;
}

.modal__close {
  width: 24px;
  height: 24px;
  justify-self: end;
  cursor: pointer;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 2.01429L17.9857 0L10 7.98571L2.01429 0L0 2.01429L7.98571 10L0 17.9857L2.01429 20L10 12.0143L17.9857 20L20 17.9857L12.0143 10L20 2.01429Z' fill='%23fff'/%3E%3C/svg%3E%0A");

  @include breakpoint(sm) {
    width: 18px;
    height: 18px;
  }
}
