.personal__main {
  padding: 0 35px 35px;
}

.personal__block {
  padding: 12px 35px;
  border-radius: 12px;
  background-color: #1f1e25;
  margin-bottom: 20px;

  &.mobile-visible {
    display: none;
    @include breakpoint(mmd) {
      display: block;
    }
  }

  &.mobile-hidden {
    display: block;
    @include breakpoint(mmd) {
      display: none;
    }
  }
}

.personal__block-title {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 120%;
  font-weight: 600;
  margin: 0;
  cursor: pointer;

  @include breakpoint(mmd) {
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;

    &.active {
      &:after {
        transform: translateY(-50%) rotateX(180deg);
      }
    }

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: calc(50% + 2px);
      transform: translateY(-50%);
      width: 16px;
      height: 10px;
      transition: all .3s ease-in-out;
      background-size: 100% 100%;
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg width='14px' height='8px' viewBox='0 -4.5 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Dribbble-Light-Preview' transform='translate(-220.000000, -6684.000000)' fill='%23ffffff'%3E%3Cg id='icons' transform='translate(56.000000, 160.000000)'%3E%3Cpath d='M164.292308,6524.36583 L164.292308,6524.36583 C163.902564,6524.77071 163.902564,6525.42619 164.292308,6525.83004 L172.555873,6534.39267 C173.33636,6535.20244 174.602528,6535.20244 175.383014,6534.39267 L183.70754,6525.76791 C184.093286,6525.36716 184.098283,6524.71997 183.717533,6524.31405 C183.328789,6523.89985 182.68821,6523.89467 182.29347,6524.30266 L174.676479,6532.19636 C174.285736,6532.60124 173.653152,6532.60124 173.262409,6532.19636 L165.705379,6524.36583 C165.315635,6523.96094 164.683051,6523.96094 164.292308,6524.36583' id='arrow_down-%5B%23338%5D'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    }
  }
}

.personal__block-inner {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 16px;

  @include breakpoint(mmd) {
    flex-direction: column;
    padding-bottom: 35px;
  }

  & .swiper-pagination-bullet {
    background-color: #d4d4d5;

    &-active {
      background-color: #00bccb;
    }
  }
}

.personal__block-icon-next {
  width: 20px;
  height: 35px;
  margin-left: 20px;
  cursor: pointer;
  transition: all .3s ease-in-out;
  flex-shrink: 0;

  &:hover {
    transform: translateX(5px);
  }

  & svg {
    width: 100%;
    height: 100%;
  }

  @include breakpoint(mmd) {
    display: none;
  }
}

.personal__block-video {
  max-width: calc(100% - 40px);
  margin-left: 0;

  @include breakpoint(mmd) {
    max-width: 100%;
  }
}

.personal__block-video .course_card {
  max-width: 170px;
  max-height: 100px;

  @include breakpoint(mmd) {
    max-width: 250px;
    max-height: 140px;
  }
}

.personal__bottom {
  display: flex;
  justify-content: space-between;

  & .personal__block:not(:last-child) {
    margin-right: 24px;
  }

  & .personal__block:first-child {
    flex-shrink: 0;
  }

  @include breakpoint(mmd) {
    flex-direction: column;
    margin-top: 35px;

    & .personal__block:not(:last-child) {
      margin-right: 0;
      margin-bottom: 14px;
    }
  }
}
