.slick-slider {
  .slick-prev, .slick-next {
    width: 16px;
    height: 26px;
    background-size: contain !important;

    &:before {
      content: '';
    }

    &:after {
      display: none;
    }

    @include breakpoint(sm) {
      width: 11px;
      height: 20px;
      top: unset;
      bottom: -20px;
      transform: translateY(100%);
    }
  }

  .slick-prev {
    background: url(../img/arrow_left.png) no-repeat center center;
    left: 8px;
  }

  .slick-next {
    background: url(../img/arrow_right.png) no-repeat center center;
    right: 8px;
  }

  .slick-list {
    margin: 0 calc(15px - 5px);
  }

  .slick-slide {
    margin: 0 5px;
  }

  .slick-dots {
    width: fit-content;
    left: 50%;
    transform: translateX(-50%);

    @include breakpoint(sm) {
      bottom: -40px;
    }

    li {
      width: 10px;
      height: 10px;
      background-color: #a7a7a7;
      margin: 0 4px;
      border-radius: 50%;

      &.slick-active {
        background: linear-gradient(to top, #c544f5, #e90345);;
      }

      button {
        &:before {
          content: '';
        }
      }
    }
  }
}

.slick-dotted.slick-slider {
  margin-bottom: 45px;
}

.slider--thumbs {
  @include breakpoint(sm) {
    margin-bottom: 39px;
  }
}
