@mixin text {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: $main-text-color;
}

@mixin small_text {
  font-size: 11px;
  font-style: italic;
}

@mixin h1 {
  font-size: 80px;
  font-weight: bold;
}

@mixin h2 {
  font-size: 26px;
  font-weight: 600;

  @include breakpoint(sm) {
    font-size: 22px;
    text-align: center;
  }
}

@mixin mini_h2 {
  font-size: 25px;
  font-weight: 400;
}

@mixin h3 {
  font-size: 20px;
  font-weight: 600;
}

@mixin main_container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px;
  @include breakpoint(ssm) {
    max-width: 100%;
    padding: 0 8px;
  }
}

@mixin link {
  font-size: 14px;
  text-decoration: none;
  color: $main-text-color;
  transition: .2s ease-in-out;
  // @include link_effects;
  &:hover {
    // text-decoration: underline;
    color: $hover_link_color;
  }
}

// @mixin link_effects {
// 	&:hover {
// 		color: $c-dark-orange;
// 	}

// 	&:active {
// 		color: $c-orange-active;
// 	}

// 	&:disabled,
// 	&[disabled],
// 	&[disabled='disabled'] {
// 		color: $c-disabled;
// 	}
// }

// @mixin section {
// 	// &:not(:last-of-type) {
// 	margin-bottom: $section-mb;
// 	@include breakpoint(tablet-md) {
// 		margin-bottom: $section-mb-tablet;
// 	}
// 	@include breakpoint(mobile-large) {
// 		margin-bottom: $section-mb-mobile;
// 	}
// 	// }
// }

@mixin input {
  display: block;
  width: 100%;
  max-width: 244px;
  height: 43px;
  padding: 13px 23px 12px 50px;
  font-size: 14px;
  background: transparent;
  color: $main-text-color;
  border-radius: 21.5px;
  border: solid 1px $main-text-color;
  overflow: hidden;
  opacity: 0.6;
  position: relative;
  transition: all 0.2s ease-in-out;

  &::placeholder {
    color: $secondary-text-color;
  }

  &:focus,
  &:active {
    opacity: 1;
  }

  // @include breakpoint(mobile-small) {
  // 	height: 40px;
  // 	font-size: 16px;
  // 	line-height: 20px;
  // }
}

@mixin btn {
  padding: 14px 13px;
  color: $main-text-color;
  font-weight: 600;
  font-size: 22px;
  font-family: inherit;
  background: $button-bg-color;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    position: relative;
    z-index: 50;
  }

  strong {
    font-size: 16px;
    font-weight: bold;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(30deg, #ff0145 0%, #e342fa 100%); // revers
    animation: btn-hover 1s linear infinite alternate;
    z-index: 10;
  }

  @include breakpoint(sm) {
    width: 100%!important;
    max-width: calc(100% - 20px)!important;
    height: 65px !important;
    font-size: 19px;
    margin-left: auto;
    margin-right: auto;
  }

  &:hover {
    color: $main-text-color;
  }
}

@keyframes btn-hover {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@mixin btn_secondary {
  background: transparent;
  border: solid 1px $main-text-color;
  &:after {
    animation: none;
    opacity: 0;
  }

  &:hover {
    &:after {
      animation: btn-hover 1s linear infinite alternate;
    }
  }
}

@mixin img_cover {
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

@mixin course_card {
  position: relative;
  height: 222px;

  &--inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    z-index: 100;
    outline: 2px solid transparent;
    outline-offset: -2px;
    background-color: rgba(23, 23, 28, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    transition: all 0.2s ease-in-out;
  }

  .category {
    margin-bottom: 16px;
  }

  h3 {
    @include h3;
    white-space: nowrap;
    @include breakpoint(xm) {
      white-space: normal;
    }
  }

  &:hover {
    .course_card--inner {
      outline-color: rgba(255, 255, 255, 0.5);
      color: #fff;
    }
  }

  &.slick-current {
    .course_card--inner {
      outline-color: $blue;
      background-color: rgba(4, 187, 201, 0.2);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@mixin accordeon {
  > li {
    position: relative;
    width: 100%;
    padding: 15px 0px;
    padding-right: 40px;
    cursor: pointer;

    > p {
      font-size: 24px;
      display: block;
      font-weight: 600;
      color: rgba(255, 255, 255, 0.9);
      user-select: none;

      @include breakpoint(sm) {
        font-size: 18px;
      }
    }

    > div {
      margin-top: 17px;
      display: none;

      p,
      a,
      span {
        font-size: 24px;
        color: rgba(255, 255, 255, 0.7);
        @include breakpoint(sm) {
          font-size: 16px;
        }
      }
    }

    &:after {
      position: absolute;
      content: '';
      right: 0px;
      top: 20px;
      transition: 0.2s ease-in-out;
      width: 26px;
      height: 14px;
      background: url(../img/arrow_down.png) no-repeat center center;

      @include breakpoint(sm) {
        top: 30px;
        width: 14px;
        height: 8px;
        background-size: contain;
      }
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      margin-bottom: 10px;
    }

    &.opened {
      &:after {
        transform: rotate(-180deg);
      }
    }
  }
}

@mixin blue_sticker {
  display: block;
  color: $main-text-color;
  background-color: $blue;
  padding: 5px 8px 6px;
  font-size: 16px;
  font-weight: 600;
  max-height: 30px;
}

@mixin underlined_heading {
  position: relative;
  max-width: 87%;
  max-width: fit-content;
  margin-bottom: 30px;
  padding-bottom: 22px;
  &:after {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    width: 100%;
    height: 4px;
    background-color: $blue;
  }
}

@mixin modules_card {
  border: 2px solid rgba(255, 255, 255, 0.4);
  width: calc(25% - 10px);
  margin: 5px;
  height: 100%;
  min-height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 32px 12px;
  transition: all 0.2s ease-in-out;
  position: relative;
  background: rgba(23, 23, 28, 0.6);

  img {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    @include img_cover;
  }

  h3,
  ul li {
    font-size: 12px;
  }

  h3 {
    @include underlined_heading;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 10px;
    padding-bottom: 8px;

    &:after {
      left: 0;
      width: calc(100% + 3px);
      height: 2px;
    }
  }

  ul {
    li {
      text-transform: uppercase;

      &:not(:last-of-type) {
        margin-bottom: 8px;
      }
    }
  }

  &:hover {
    text-decoration: none;
    border-color: #fff;
  }

  @include breakpoint(xx) {
    width: calc((100% / 3) - 10px);
  }

  @include breakpoint(mmd) {
    width: calc(50% - 10px);
  }

  @include breakpoint(sm) {
    width: 100%;
    max-width: 100%;
  }
}

@mixin course_mini_card {
  @include modules_card;
  padding: 8px 8px 27px;
  min-height: 113px;

  h3 {
    font-size: 10px;
    margin-bottom: 0;
  }
}
