.editors_choice {
	margin-bottom: 10px;
    > div {
        display: flex;
        align-items: center;

        @include breakpoint(mmd) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

	&__hero {
		width: 100%;
		max-width: 775px;
		margin-right: 50px;
		height: 435px;
		box-shadow: 0 0 16.7px 1.3px rgba(4, 187, 201, 0.32);
		position: relative;

        @include breakpoint(mmd) {
            margin-right: 0;
            margin-bottom: 31px;
        }

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&--inner {
			position: absolute;
			width: 100%;
			height: 100%;
			background-color: rgba(23, 23, 28, 0.6);
			left: 0;
			top: 0;
			padding: 21px 14px 50px 32px;
			z-index: 100;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
		}

		.icons {
            position: absolute;
            top: 21px;
            left: 32px;

			a {
				width: 40px;
				height: 40px;

				img {
					width: 29px;
					height: 25px;
				}

				&:first-of-type {
					margin-right: 17px;
					img {
						width: 30px;
						height: 26px;
					}
				}
			}
		}

        .category {
            position: absolute;
            top: 21px;
            right: 14px;
            font-size: 20px;
            white-space: normal;
            max-width: 245px;
            max-height: 78px;
            padding: 15px 28px 17px 24px;

            @include breakpoint(lg) {
                font-size: 16px;
                padding: 8px 16px;
                max-width: 200px;
            }

            @include breakpoint(sm) {
                font-size: 14px;
            }
        }

        h1, h2 {
            @include underlined_heading;

            & + p {
                margin-bottom: 14px;
            }

            & + p + p {
                font-size: 18px;
            }
        }
	}

	&__info {
		width: auto;

        h2 {
            margin-bottom: 36px;
            max-width: 87%;

            & + p {
                font-size: 24px;
                margin-bottom: 19px;
            }

            @include breakpoint(mmd) {
                display: none;
            }
        }

        ul.details {
            @include small_text;
            column-count: 2;
            padding-left: 30px;
            opacity: .8;
            width: 308px;
            margin-bottom: 33px;
            white-space: nowrap;
            position: relative;

            @include breakpoint(xss) {
                white-space: normal;
            }

            li {
                @include breakpoint(xss) {
                    max-width: 115px;
                }
            }

            &:before {
                position: absolute;
                content: '';
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 20px;
                height: 18px;
                background: url(../img/heart.png) no-repeat center center;
                background-size: contain;
            }
        }

        .salary {
            font-size: 14px;
        }

        button {
            width: 350px;
            height: 70px;
            margin-top: 60px;
        }
	}

    .mobile_title {
        display: none;
        text-align: center;
        font-size: 22px;
        margin-bottom: 31px;

        @include breakpoint(mmd) {
            display: block;
        }
    }
}
