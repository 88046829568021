.all_courses {
  margin-bottom: 74px;

  h2 {
    @include mini_h2;
    margin-bottom: 27px;
  }

  &__grid {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: -10px -5px;

    .course_card {
      margin: 10px 5px;
      width: calc(100% / 3 - 10px);

      @include breakpoint(lg) {
        width: calc(50% - 10px);
      }

      @include breakpoint(sm) {
        width: 100%;
        max-width: 100%;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .loadmore {
    margin-top: 36px;
  }
}
