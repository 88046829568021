//TODO: перенести на медиа запросы

.modules {
  margin-bottom: 44px;
  position: relative
}

.modules h2 {
  margin-bottom: 10px;
  font-size: 24px
}

.modules .accordeon > li > p {
  font-size: 20px
}

.modules__btn {
  margin-top: 35px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  padding-left: 58px
}

.modules__btn:before {
  position: absolute;
  content: "";
  left: 18px;
  top: 50%;
  transform: translateY(-50%);
  background: url(../img/arrow_down.png) no-repeat 50%;
  background-size: contain;
  width: 25px;
  height: 14px;
  z-index: 100
}

@media screen and (max-width: 600px) {
  .modules__btn {
    display: none
  }
}

.modules__grid {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -5px
}

.modules__grid, .modules__grid__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.modules__grid__item {
  border: 2px solid hsla(0, 0%, 100%, .4);
  width: calc(25% - 10px);
  margin: 5px;
  height: 100%;
  min-height: 170px;
  flex-direction: column;
  justify-content: flex-end;
  padding: 32px 12px;
  transition: all .2s ease-in-out;
  position: relative;
  background: rgba(23, 23, 28, .6)
}

.modules__grid__item img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover
}

.modules__grid__item h3, .modules__grid__item ul li {
  font-size: 12px
}

.modules__grid__item h3 {
  position: relative;
  max-width: 87%;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  font-weight: 600;
  margin-bottom: 10px;
  padding-bottom: 8px
}

.modules__grid__item h3:after {
  position: absolute;
  content: "";
  bottom: 0;
  width: 100%;
  height: 4px;
  background-color: #04bbc9;
  left: 0;
  width: calc(100% + 3px);
  height: 2px
}

.modules__grid__item ul li {
}

.modules__grid__item ul li:not(:last-of-type) {
  margin-bottom: 8px
}

.modules__grid__item:hover {
  text-decoration: none;
  border-color: #fff
}

@media screen and (max-width: 1100px) {
  .modules__grid__item {
    width: calc(33.33333% - 10px)
  }
}

@media screen and (max-width: 900px) {
  .modules__grid__item {
    width: calc(50% - 10px)
  }
}

@media screen and (max-width: 600px) {
  .modules__grid__item {
    width: 100%;
    max-width: 100%
  }
}
